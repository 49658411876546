export default [
  {
    title: 'Dashboard',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Administração',
    icon: 'PieChartIcon',
    children: [
      {
        title: 'Áreas de trabalho',
        route: 'workspaces',
        icon: 'FileIcon',
      },
      {
        title: 'Usuarios',
        route: 'users',
        icon: 'UserIcon',
      },
    ],
  },
  {
    title: '',
    icon: 'ClipboardIcon',
    children: [
      {
        title: 'Colaboradores',
        route: 'colaboradores',
        icon: 'UserIcon',
      },
      {
        title: 'Minhas tarefas',
        route: 'minhas-tarefas',
        icon: 'ListIcon',
      },
      {
        title: 'Tarefas da equipe',
        route: 'tarefas-da-equipe',
        icon: 'CalendarIcon',
      },
      {
        title: 'Projetos',
        route: 'projetos',
        icon: 'GridIcon',
      },
      {
        title: 'Exportação de dados',
        route: 'exportar-dados',
        icon: 'FileTextIcon',
      },
    ],
  },
  {
    title: 'Financeiro',
    icon: 'BarChartIcon',
    children: [
      {
        title: 'Receita',
        route: 'lancamento-de-receitas',
        icon: 'PercentIcon',
      },
      {
        title: 'Impostos',
        route: 'taxes',
        icon: 'PercentIcon',
      },
      {
        title: 'Fornecedores',
        route: 'suppliers',
        icon: 'TruckIcon',
      },
      {
        title: 'Custos de projeto',
        route: 'custos-de-projeto',
        icon: 'PieChartIcon',
      },
      {
        title: 'Custos de escritório',
        route: 'custos-de-escritorio',
        icon: 'PieChartIcon',
      },
      {
        title: 'Categoria de custo',
        route: 'costsCategory',
        icon: 'PieChartIcon',
      },
      {
        title: 'Bens depreciáveis',
        route: 'depreciableAssets',
        icon: 'ArchiveIcon',
      },
      {
        title: 'Calculos de custo',
        route: 'calculo-de-custos',
        icon: 'DollarSignIcon',
      },
    ],
  },
  {
    title: 'Mudar workspace',
    route: 'trocar-workspace',
    icon: 'LogInIcon',
  },
];
